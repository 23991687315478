import { useQuery } from "react-query";
import { APP_API_BASE } from "../utils/config";

export interface DiscordGuild {
  id: string;
  name: string;
  icon: string;
  owner: boolean;
  permissions: number;
  features: string[];
  permissions_new: string;
}

export interface DiscordGuilds {
  guilds: DiscordGuild[];
  userId: string;
  userEmail: string;
  paywhirlPlan?: string;
  paywhirlSubscriptionId?: string;
  coupon?: string;
}

export function useGuildsQuery(sessionId: string | undefined) {
  async function loadGuilds(): Promise<DiscordGuilds> {
    console.log("fetching guilds ... " + sessionId);
    const response = await fetch(APP_API_BASE + "/api/member/user/guilds", {
      headers: {
        "x-botsy-session": sessionId!,
      },
    });
    return await response.json();
  }

  return useQuery<DiscordGuilds>("guilds", loadGuilds, {
    enabled: !!sessionId,
  });
}
