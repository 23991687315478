import React from "react";
import tickMark from "../img/tick-mark.svg";

const FeatureList: React.FC = () => {
  return (
    <ul className="list-group list-group-flush">
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Unlimited Stickies
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Use Custom Embeds as Stickies
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Create a Sticky Poll
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Use Custom Name and PFP
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Create Slower Posting Stickies
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Set a Custom Bot Prefix
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Removes "Stickied Message" Header
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Support Server Premium Access
      </li>
      <li className="list-group-item">
        <img alt="" src={tickMark} width="20px" height="20px" /> Cancel Anytime
      </li>
    </ul>
  );
};

export default FeatureList;
