import React from "react";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { STATIC_CONFIG } from "../utils/config";
import { PaddleSubscriptionPlansConfig } from "../utils/plans";

interface LocationState {
  planType: string;
  serverName: string;
}

const Success: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const planType = state?.planType || "monthly";
  const serverName = state?.serverName || "My Big Popular Server";
  const planConfig = PaddleSubscriptionPlansConfig[planType];

  return (
    <div className="row g-0 justify-content-center mb-10">
      <div className="col-md-6">
        <div className={`card ${planConfig.borderClass}`}>
          <div className="card-body">
            <h2 className="py-5 mb-5 text-center">
              &#9989; Thanks for subscribing to StickyBot <em>Premium</em>!
              &#9989;
            </h2>
            <p>
              You can now use all the premium StickyBot features, such as embed
              stickies, webhook stickies, slow stickies, and changing the prefix
              in your server:
            </p>
            <p className="my-5 fs-3 text-center">{serverName}</p>
            <p className="my-5">
              To learn more about the premium features, go to your Discord
              server and type the command,{" "}
              <code className="text-nowrap">?help</code>. Then click on the blue{" "}
              <strong>"&#129505; Premium"</strong> button in the help message to
              see the list of available premium commands.
            </p>
            <p className="mb-0">
              Or view the documentation on-line :{" "}
              <Button variant="primary" href={STATIC_CONFIG.DOCS_PREMIUM_URL}>
                StickyBot Premium Documentation
              </Button>
            </p>
          </div>
          <div className="card-footer">
            <p>
              Stay up-to-date on everything happening with StickyBot by joining
              our friendly Discord community in the{" "}
              <a href={STATIC_CONFIG.DISCORD_INVITE_URL}>
                StickyBot Development server.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
