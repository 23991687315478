export const PaddleSubscriptionPlans: any = {
  monthly: {
    // sandbox
    local: {
      planNumber: 30762,
      planCost: 6.59,
      planCostOrig: 8
    },
    // production
    prod: {
      planNumber: 774960,
      planCost: 5,
      planCostOrig: 5
    },
  },
  yearly: {
    local: {
      planNumber: 30763,
      planCost: 72,
      planCostOrig: 20
    },
    prod: {
      planNumber: 774961,
      planCost: 50,
      planCostOrig: 50
    },
  },
};

export const PaddleSubscriptionPlansConfig: any = {
  monthly: {
    planType: "monthly",
    simpleText: "month",
    officialText: "Monthly",
    borderClass: "border-primary",
    buttonClass: "btn-primary",
    otherPlan: {
      planType: "yearly",
      officialText: "Yearly",
    },
  },
  yearly: {
    planType: "yearly",
    simpleText: "year",
    officialText: "Yearly",
    borderClass: "border-sbot",
    buttonClass: "btn-sbot",
    otherPlan: {
      planType: "monthly",
      officialText: "Monthly",
    },
  },
};
