import React from "react";
import FeatureList from "../components/FeatureList";
import PlanHeader from "../components/PlanHeader";
import PremiumUsp from "../components/PremiumUsp";
import { APP_API_BASE } from "../utils/config";

function Plans() {
  function onMonthly() {
    console.log("monthly");
    // Using the 'href' property sends it to the same-origin.
    // window.location.href = APP_API_BASE + "/api/member/auth/start?deeplink=monthly";

    // @ts-ignore
    // Without the 'href' property sends it to the origin you specify here.
    window.location = APP_API_BASE + "/api/member/auth/start?deeplink=monthly";
  }

  function onYearly() {
    console.log("yearly");
    // @ts-ignore
    window.location = APP_API_BASE + "/api/member/auth/start?deeplink=yearly";
  }

  return (
    <>
      <PremiumUsp></PremiumUsp>
      <div className="row g-4 justify-content-center mb-10">
        <div className="col-md-4">
          <div className="card card-stretch border-primary">
            <div className="card-body">
              <PlanHeader planType="monthly" />
              <FeatureList />
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button onClick={onMonthly} className="btn btn-primary btn-lg">
                  Get the Monthly plan
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card card-stretch border-sbot">
            <div className="card-body">
              <PlanHeader planType="yearly" />
              <FeatureList />
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button onClick={onYearly} className="btn btn-sbot btn-lg">
                  <strong>Get the Yearly plan</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plans;
