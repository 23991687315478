import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PlanHeader from "../components/PlanHeader";
import PremiumUsp from "../components/PremiumUsp";
import { useGuildsQuery } from "../hooks/useMemberServiceApi";
import { APP_ENV, STATIC_CONFIG } from "../utils/config";
import {
  PaddleSubscriptionPlans,
  PaddleSubscriptionPlansConfig,
} from "../utils/plans";

interface IPaddle {
  Checkout: ICheckout;
}

interface ICheckout {
  open(options: any): void;
}

interface PaddlePassthrough {
  discordGuildId: string;
  userId: string;
  paywhirlPlan?: string;
  paywhirlSubscriptionId?: string;
}

function Plan() {
  const { planType, sessionId } = useParams();
  const [selectedGuildId, setSelectedGuildId] = useState<string>();
  const [selectedGuildName, setSelectedGuildName] = useState<string>();
  const planConfig = PaddleSubscriptionPlansConfig[planType!];
  const planInfo = PaddleSubscriptionPlans[planType!][APP_ENV];
  const navigateSuccess = useNavigate();

  function navToSuccess() {
    navigateSuccess("/success", {
      replace: true,
      state: { planType: planType, serverName: selectedGuildName },
    });
  }

  function onGetPlan() {
    let passthrough: PaddlePassthrough = {
      discordGuildId: selectedGuildId!,
      userId: guilds!.userId,
      paywhirlPlan: guilds!.paywhirlPlan,
      paywhirlSubscriptionId: guilds!.paywhirlSubscriptionId,
    };
    console.log(
      `get the plan, ${planType}, for '${selectedGuildName}' (${selectedGuildId}). ${JSON.stringify(
        passthrough
      )}`
    );
    const Paddle: IPaddle = (window as any).Paddle;
    Paddle.Checkout.open({
      product: planInfo.planNumber,
      // Note: Because we are passing in the email, the email screen in the overlay won't show. So
      // the marketing_consent will always be false.
      email: guilds!.userEmail,
      coupon: guilds!.coupon,
      passthrough: JSON.stringify(passthrough),
      successCallback: (data: any, err: any) => {
        console.log("back from Paddle: ", data);
        navToSuccess();
      },
    });
  }

  function onSelectGuild(id: string, name: string) {
    setSelectedGuildId(id);
    setSelectedGuildName(name);
  }

  const { isLoading, isError, data: guilds } = useGuildsQuery(sessionId);

  if (isError) {
    return <p>Error!</p>;
  }
  if (isLoading || !guilds) {
    return <p>Loading...</p>;
  }
  if (guilds.guilds.length < 1) {
    return (
      <>
        <p>Looks like you don't belong to any Discord servers.</p>
        <p>
          Try joining a server first, then try{" "}
          <a href={STATIC_CONFIG.SITE_PREMIUM}>purchasing again.</a>
        </p>
      </>
    );
  }

  // Select the first guild, if no manual selection has been made yet.
  if (!selectedGuildId) {
    onSelectGuild(guilds.guilds[0].id, guilds.guilds[0].name);
  }

  return (
    <>
      <PremiumUsp></PremiumUsp>
      <div className="row g-4 justify-content-center mb-10">
        <div className="col-md-6">
          <div className={`card ${planConfig.borderClass}`}>
            <div className="card-body">
              <PlanHeader planType={planType!} />

              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-secondary p-7">
                  <h3 className="text-center pb-3">
                    Select the server where you want to use
                    <br />
                    StickyBot <em>Premium</em>
                  </h3>
                  <select
                    value={selectedGuildId}
                    onChange={(e) =>
                      onSelectGuild(
                        e.target.value,
                        e.target.selectedOptions[0].label
                      )
                    }
                    className="form-select"
                    aria-label="Select Server"
                  >
                    {guilds.guilds.map((g) => {
                      return (
                        <option key={g.id} value={g.id}>
                          {g.name}
                        </option>
                      );
                    })}
                  </select>
                </li>
              </ul>
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button
                  onClick={onGetPlan}
                  className={`btn btn-lg ${planConfig.buttonClass} fw-bolder fs-3`}
                >
                  Get the {planConfig.officialText} plan
                </button>
              </div>
            </div>
          </div>

          <div className="text-center mt-4">
            <Link
              className="text-inverse-light"
              to={`/${planConfig.otherPlan.planType}/${sessionId}`}
            >
              Or view the {planConfig.otherPlan.officialText} plan...
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plan;
