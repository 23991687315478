import { AppEnvironmentTypeMap, HostMapType } from "./config-types";

export const HOST_MAP: HostMapType = {
  local: "localhost",
  // local: "app.stickybot.info",
  prod: "app.stickybot.info",
};

export const ENV_MAP: AppEnvironmentTypeMap = {
  local: {
    envName: "local",
    apiBase: "http://localhost:9106",
    // apiBase: "https://app.stickybot.info",
  },
  prod: {
    envName: "prod",
    apiBase: "https://app.stickybot.info",
  },
};
