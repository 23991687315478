import React from "react";
import { APP_ENV } from "../utils/config";
import {
  PaddleSubscriptionPlansConfig,
  PaddleSubscriptionPlans,
} from "../utils/plans";

interface PlanHeaderProps {
  planType: string;
}

function PlanHeader(props: PlanHeaderProps) {
  const planConfig = PaddleSubscriptionPlansConfig[props.planType];
  const planInfo = PaddleSubscriptionPlans[props.planType][APP_ENV];

  return (
    <>
      <p className="h3 card-title text-center mb-5">
        {planConfig.officialText} plan
      </p>
      <p className="display-5 card-title text-center mb-1">
        ${planInfo.planCost} / {planConfig.simpleText}
      </p>
      <p className="text-center text-muted mb-7">
        Billed every {planConfig.simpleText}
      </p>
    </>
  );
}

export default PlanHeader;
