import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./img/stickybot-logo.png";
import Plan from "./pages/Plan";
import Plans from "./pages/Plans";
import Success from "./pages/Success";
import { STATIC_CONFIG } from "./utils/config";
import { getBaseUrl } from "./utils/location-util";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        if (error === 401) {
          window.location.href = getBaseUrl();
        }
      },
      retry: (failureCount, error) => error !== 401,
      // 15 minutes stale time.
      staleTime: 900000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Navbar>
          <Container>
            <Navbar.Brand
              href={STATIC_CONFIG.SITE_HOME}
              className="d-flex align-items-center overflow-hidden"
            >
              <img
                alt="StickyBot"
                src={logo}
                width="80"
                height="80"
                className="d-inline-block align-top me-4"
              />{" "}
              <span className="display-5 fw-light text-light">
                StickyBot - <em>Premium</em>
              </span>
            </Navbar.Brand>
          </Container>
        </Navbar>
        <main className="flex-shrink-0">
          <Container className="">
            <Routes>
              <Route path="/:planType/:sessionId" element={<Plan />} />
              <Route path="/success" element={<Success />} />
              <Route path="/" element={<Plans />} />
            </Routes>
          </Container>
        </main>
        <footer className="mt-auto py-3 bg-light text-center text-lg-start">
          <div className="text-center p-3">
            Copyright © 2020 - {new Date().getFullYear()}{" "}
            <a className="" href={STATIC_CONFIG.SITE_HOME}>
              StickyBot
            </a>
            , All rights reserved.
            <br />
            <a href={STATIC_CONFIG.SITE_PRIVACY}>Privacy Policy</a> |{" "}
            <a href={STATIC_CONFIG.SITE_TERMS}>Terms of Service</a>
          </div>
        </footer>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
